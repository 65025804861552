<template>
  <div class="form">
    <div class="form-wrapper">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfysaNn1lyvM4v8IO2i2Swm20wyXMEX1DxDaWJL0n7s7Bs3Og/viewform?embedded=true"
        width="640"
        height="900"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Form"
});
</script>

<style lang="scss" scoped>
.form {
  padding-top: calc(16px + constant(safe-area-inset-top));
  padding-right: calc(16px + constant(safe-area-inset-right));
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-left: calc(16px + constant(safe-area-inset-left));
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  padding-left: calc(16px + env(safe-area-inset-left));
  &-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    margin-left: auto;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 720px) {
  .form-wrapper {
    padding-top: 300%;
  }
}

@media screen and (min-width: 1280px) {
  .form-wrapper {
    padding-top: 80%;
  }
}
</style>
