<template>
  <div class="logo" @click="execHome">{{ labels.title }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import labels from "@/mixins/labels.json";

export default defineComponent({
  name: "Logo",
  data() {
    return {
      labels: labels
    };
  },
  methods: {
    execHome() {
      this.$router.push("/");
    }
  }
});
</script>

<style lang="scss" scoped>
.logo {
  font-size: 32px;
  user-select: none;
  cursor: pointer;
}
</style>
