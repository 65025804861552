<template>
  <div class="home">
    <!-- <div class="coming-soon" v-if="articles.length === 0">
      {{ labels.comingSoon }}
    </div> -->
    <article-component
      v-for="(article, index) in articles"
      :key="index"
      :data="article"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";
import { ACTOR_LIST } from "@/mixins/actorList";
import labels from "@/mixins/labels.json";

import ArticleComponent from "@/components/organisms/ArticleComponent.vue";

export default defineComponent({
  name: "Home",
  components: {
    ArticleComponent
  },
  data() {
    return {
      articles: [],
      labels: labels
    };
  },
  async created() {
    await db
      .collection("articles")
      .orderBy("release_date", "desc")
      .startAfter(new Date())
      .get()
      .then(async (querySnapshot: any) => {
        await querySnapshot.forEach(async (doc: any) => {
          const object = await doc.data();
          object.actorList = this.setActorData(doc.data().actors);
          await (this as any).articles.push(object);
        });
      });
  },
  methods: {
    setActorData(list: any) {
      const actorList: any = [];
      list.forEach((id: number) => {
        const actorData = ACTOR_LIST.filter((actor: any) => {
          return actor.id === id && actor.visible;
        });
        actorList.push(actorData[0]);
      });
      return actorList;
    }
  }
});
</script>

<style lang="scss" scoped>
.home {
  padding-top: calc(16px + constant(safe-area-inset-top));
  padding-right: calc(16px + constant(safe-area-inset-right));
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-left: calc(16px + constant(safe-area-inset-left));
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  padding-left: calc(16px + env(safe-area-inset-left));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .coming-soon {
    width: 100%;
    height: calc(100vh - 352px);
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .article-component {
    width: calc(50% - 64px);
    margin: 32px;
  }
}

@media screen and (max-width: 720px) {
  .home {
    .article-component {
      width: 100%;
      margin: 8px 8px 16px 8px !important;
    }
  }
}

@media screen and (min-width: 1280px) {
  .home {
    .article-component {
      width: calc(33% - 64px);
      margin: 32px;
    }
  }
}
</style>
