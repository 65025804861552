
import { defineComponent } from "vue";
import { storage } from "@/plugins/firebase";
import moment from "moment";

import ActorIcon from "@/components/atoms/ActorIcon.vue";

export default defineComponent({
  name: "ArticleComponent",
  components: {
    ActorIcon,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      thumbnailUrl: "",
    };
  },
  mounted() {
    storage
      .ref()
      .child(
        `article_thumbnails/thumbnail_${
          (this as any).data.type + "_" + (this as any).data.id
        }.jpg`
      )
      .getDownloadURL()
      .then((url) => {
        this.thumbnailUrl = url;
      });
  },
  computed: {
    formatDate() {
      return moment
        .unix((this as any).data.release_date.seconds)
        .format("YYYY年MM月DD日");
    },
  },
  methods: {
    execArticle() {
      this.$router.push(
        `/article/${(this as any).data.type}/${(this as any).data.id}`
      );
    },
  },
});
