
import { defineComponent } from "vue";

import Logo from "@/components/atoms/Logo.vue";
import HomeDescription from "@/components/atoms/HomeDescription.vue";
import Navi from "@/components/molecules/Navi.vue";

export default defineComponent({
  name: "GlobalHeader",
  components: {
    Logo,
    HomeDescription,
    Navi
  }
});
