
import { defineComponent } from "vue";

import Link from "@/components/atoms/Link.vue";

export default defineComponent({
  name: "ArticleComponent",
  components: {
    Link
  },
  props: {
    data: Object
  },
  methods: {
    execActor() {
      this.$router.push(`/actor/${(this as any).data.id}`);
    },
    execLink(link: string) {
      window.open(link, "_blank");
    }
  }
});
