
import { defineComponent } from "vue";

import { NAVI_LIST } from "@/mixins/naviList.ts";
import NaviItem from "@/components/atoms/NaviItem.vue";

export default defineComponent({
  name: "Navi",
  components: {
    NaviItem
  },
  data() {
    return {
      list: NAVI_LIST
    };
  }
});
