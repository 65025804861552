
import { defineComponent } from "vue";

import labels from "@/mixins/labels.json";

export default defineComponent({
  name: "TopDescription",
  data() {
    return {
      labels: labels
    };
  }
});
