
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";

import ActorComponent from "@/components/organisms/ActorComponent.vue";

export default defineComponent({
  name: "Actors",
  components: {
    ActorComponent
  },
  data() {
    return {
      actors: []
    };
  },
  created() {
    db.collection("actors")
      .orderBy("id", "asc")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach(async (doc: any) => {
          await (this as any).actors.push(doc.data());
        });
      });
  }
});
