<template>
  <div class="article-component" @click="execArticle">
    <img :src="thumbnailUrl" />
    <div class="title">
      {{ data.title }}{{ data.type === 1 ? "&nbsp;#" + data.id : "" }}
    </div>
    <div class="article-component-footer">
      <div class="date">{{ formatDate }}</div>
      <div class="actor-component">
        <actor-icon
          v-for="(actor, index) in data.actorList"
          :key="index"
          :data="actor"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storage } from "@/plugins/firebase";
import moment from "moment";

import ActorIcon from "@/components/atoms/ActorIcon.vue";

export default defineComponent({
  name: "ArticleComponent",
  components: {
    ActorIcon,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      thumbnailUrl: "",
    };
  },
  mounted() {
    storage
      .ref()
      .child(
        `article_thumbnails/thumbnail_${
          (this as any).data.type + "_" + (this as any).data.id
        }.jpg`
      )
      .getDownloadURL()
      .then((url) => {
        this.thumbnailUrl = url;
      });
  },
  computed: {
    formatDate() {
      return moment
        .unix((this as any).data.release_date.seconds)
        .format("YYYY年MM月DD日");
    },
  },
  methods: {
    execArticle() {
      this.$router.push(
        `/article/${(this as any).data.type}/${(this as any).data.id}`
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.article-component {
  cursor: pointer;
  padding: 16px;
  box-shadow: 0 0 4px black;
  img {
    width: 100%;
    margin-bottom: 16px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
    min-height: 58px;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: top;
    .date {
      color: gray;
    }
    .actor-component {
      .actor-icon {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
