
import { defineComponent } from "vue";

export default defineComponent({
  name: "ActorIcon",
  props: {
    data: Object
  },
  methods: {
    execActor() {
      this.$router.push(`/actor/${(this as any).data.id}`);
    }
  }
});
