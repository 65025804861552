import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCQTLAzAtJQJmiiedFyf-mIcKE9It_9f1I",
    authDomain: "digit-eight.firebaseapp.com",
    projectId: "digit-eight",
    storageBucket: "digit-eight.appspot.com",
    messagingSenderId: "928612335741",
    appId: "1:928612335741:web:3de5ed31a84153eb0e3114",
    measurementId: "G-3QPQ5SNBJ4"
  });
}

firebase.analytics();

export const db = firebase.firestore();
export const storage = firebase.storage();
