<template>
  <div class="navi-item" @click="exec">
    {{ data.label }}
    <img v-if="data.link" src="@/assets/img/icon/launch.svg" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NaviItem",
  props: {
    data: Object
  },
  methods: {
    exec(): void {
      if ((this as any).data.routerName) {
        this.$router.push((this as any).data.routerName);
      } else {
        window.open((this as any).data.link, "_blank");
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.navi-item {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-left: 2px;
  }
}

@media screen and (max-width: 420px) {
  .navi-item {
    font-size: 12px;
    img {
      width: 16px;
    }
  }
}
</style>
