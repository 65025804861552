interface IactorList {
  id: number;
  label: string;
  iconPath?: string;
  visible?: boolean;
}

export const ACTOR_LIST: IactorList[] = [
  {
    id: 1,
    label: "エビテ",
    iconPath: "ebite",
    visible: true
  },
  {
    id: 2,
    label: "カニ",
    iconPath: "kani",
    visible: true
  },
  {
    id: 3,
    label: "ナガイキ",
    iconPath: "nagaiki",
    visible: true
  },
  {
    id: 4,
    label: "ラズ",
    iconPath: "ras",
    visible: true
  }
];
