<template>
  <div class="actor-icon" @click.stop="execActor">
    <img :src="require(`@/assets/img/profile_icons/${data.iconPath}.png`)" />
    <div class="label">{{ data.label }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ActorIcon",
  props: {
    data: Object
  },
  methods: {
    execActor() {
      this.$router.push(`/actor/${(this as any).data.id}`);
    }
  }
});
</script>

<style lang="scss" scoped>
.actor-icon {
  cursor: pointer;
  width: 120px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 4px black;
    margin-right: 8px;
  }
  .label {
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
