
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";
import { ACTOR_LIST } from "@/mixins/actorList";
import labels from "@/mixins/labels.json";

import ArticleComponent from "@/components/organisms/ArticleComponent.vue";

export default defineComponent({
  name: "Home",
  components: {
    ArticleComponent
  },
  data() {
    return {
      articles: [],
      labels: labels
    };
  },
  async created() {
    await db
      .collection("articles")
      .orderBy("release_date", "desc")
      .startAfter(new Date())
      .get()
      .then(async (querySnapshot: any) => {
        await querySnapshot.forEach(async (doc: any) => {
          const object = await doc.data();
          object.actorList = this.setActorData(doc.data().actors);
          await (this as any).articles.push(object);
        });
      });
  },
  methods: {
    setActorData(list: any) {
      const actorList: any = [];
      list.forEach((id: number) => {
        const actorData = ACTOR_LIST.filter((actor: any) => {
          return actor.id === id && actor.visible;
        });
        actorList.push(actorData[0]);
      });
      return actorList;
    }
  }
});
