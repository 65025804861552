interface InaviItem {
  name: string;
  label: string;
  routerName?: string;
  visible?: boolean;
  link?: string;
}

export const NAVI_LIST: InaviItem[] = [
  {
    name: "home",
    label: "Home",
    routerName: "/",
    visible: true
  },
  {
    name: "actors",
    label: "Actors",
    routerName: "/actors",
    visible: true
  },
  {
    name: "form",
    label: "Form",
    routerName: "/form",
    visible: true
  },
  {
    name: "youtube",
    label: "YouTube",
    link: "https://www.youtube.com/channel/UCuGI3kOZwB02tT2MLPfOgWg",
    visible: true
  },
  {
    name: "twitter",
    label: "Twitter",
    link: "https://twitter.com/digit_eight_",
    visible: true
  }
];
