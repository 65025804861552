<template>
  <div class="actor">
    <div class="actor-wrapper" v-if="actor.icon">
      <div class="actor-header">
        <img
          :src="
            require(`@/assets/img/profile_icons/${
              actor.icon ? actor.icon + '.png' : 'person.svg'
            }`)
          "
        />
        <div class="name">{{ actor.name }}</div>
      </div>
      <div class="actor-content">
        <div
          v-for="(text, index) in actor.description"
          :key="index"
          class="content-section"
        >
          {{ text }}
        </div>
      </div>
      <div class="actor-footer">
        <div class="links">
          <Link
            v-for="(item, index) in actor.sns"
            :key="index"
            :label="item.platform"
            :url="item.url"
            :sns="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";

import Link from "@/components/atoms/Link.vue";

export default defineComponent({
  name: "Actor",
  components: {
    Link
  },
  data() {
    return {
      actor: {}
    };
  },
  created() {
    db.collection("actors")
      .where("id", "==", Number(this.$route.params.actorId))
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          this.actor = doc.data();
        });
      });
  },
  methods: {
    execLink(link: string) {
      window.open(link, "_blank");
    }
  }
});
</script>

<style lang="scss" scoped>
.actor {
  padding-top: calc(32px + constant(safe-area-inset-top));
  padding-right: calc(32px + constant(safe-area-inset-right));
  padding-bottom: calc(32px + constant(safe-area-inset-bottom));
  padding-left: calc(32px + constant(safe-area-inset-left));
  padding-top: calc(32px + env(safe-area-inset-top));
  padding-right: calc(32px + env(safe-area-inset-right));
  padding-bottom: calc(32px + env(safe-area-inset-bottom));
  padding-left: calc(32px + env(safe-area-inset-left));
  .actor-wrapper {
    padding: 16px;
    box-shadow: 0 0 4px black;
    .actor-header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: 0 0 4px black;
        margin-right: 16px;
      }
      .name {
        font-size: 28px;
      }
    }
    .actor-content {
      margin-bottom: 16px;
      .content-section {
        line-height: 32px;
      }
    }
    .actor-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
