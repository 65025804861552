import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Actors from "../views/Actors.vue";
import Actor from "../views/Actor.vue";
import Article from "../views/Article.vue";
import Form from "../views/Form.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/actors",
    name: "Actors",
    component: Actors
  },
  {
    path: "/actor/:actorId",
    name: "Actor",
    component: Actor
  },
  {
    path: "/article/:typeId/:articleId",
    name: "Article",
    component: Article
  },
  {
    path: "/form",
    name: "Form",
    component: Form
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
