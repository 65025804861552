<template>
  <div class="actors">
    <actor-component
      v-for="(actor, index) in actors"
      :key="index"
      :data="actor"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";

import ActorComponent from "@/components/organisms/ActorComponent.vue";

export default defineComponent({
  name: "Actors",
  components: {
    ActorComponent
  },
  data() {
    return {
      actors: []
    };
  },
  created() {
    db.collection("actors")
      .orderBy("id", "asc")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach(async (doc: any) => {
          await (this as any).actors.push(doc.data());
        });
      });
  }
});
</script>

<style lang="scss" scoped>
.actors {
  padding-top: calc(16px + constant(safe-area-inset-top));
  padding-right: calc(16px + constant(safe-area-inset-right));
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-left: calc(16px + constant(safe-area-inset-left));
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  padding-left: calc(16px + env(safe-area-inset-left));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .actor-component {
    width: calc(50% - 16px);
    margin: 8px;
  }
}

@media screen and (max-width: 420px) {
  .actors {
    .actor-component {
      width: 100%;
      margin: 16px;
    }
  }
}

@media screen and (min-width: 1080px) {
  .actors {
    .actor-component {
      width: calc(33% - 64px);
      margin: 32px;
    }
  }
}
</style>
