<template>
  <div class="global-footer">
    <div class="navi-wrapper">
      <navi />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Navi from "@/components/molecules/Navi.vue";

export default defineComponent({
  name: "GlobalFooter",
  components: {
    Navi
  }
});
</script>

<style lang="scss" scoped>
.global-footer {
  position: relative;
  width: 100%;
  object-fit: cover;
  background: url("./../../assets/img/background.jpg") center left no-repeat;
  height: 80px;
  padding-top: calc(16px + constant(safe-area-inset-top));
  padding-right: calc(16px + constant(safe-area-inset-right));
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-left: calc(16px + constant(safe-area-inset-left));
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  padding-left: calc(16px + env(safe-area-inset-left));
  color: black;
  text-shadow: 2px 0px 5px white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 10px black;
}
</style>
