<template>
  <div class="actor-component" @click="execActor" v-show="data">
    <img
      class="profile"
      :src="
        require(`@/assets/img/profile_icons/${
          data.icon ? data.icon + '.png' : 'person.svg'
        }`)
      "
    />
    <div class="actor-component-section">
      <div class="name">{{ data.name }}</div>
      <div class="links">
        <Link
          v-for="(item, index) in data.sns"
          :key="index"
          :label="item.platform"
          :url="item.url"
          :sns="true"
        />
      </div>
    </div>
    <div class="actor-component-icon-wrapper">
      <img class="icon" src="@/assets/img/icon/more.svg" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Link from "@/components/atoms/Link.vue";

export default defineComponent({
  name: "ArticleComponent",
  components: {
    Link
  },
  props: {
    data: Object
  },
  methods: {
    execActor() {
      this.$router.push(`/actor/${(this as any).data.id}`);
    },
    execLink(link: string) {
      window.open(link, "_blank");
    }
  }
});
</script>

<style lang="scss" scoped>
.actor-component {
  display: flex;
  cursor: pointer;
  padding: 16px;
  box-shadow: 0 0 4px black;
  .profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 4px black;
  }
  &-section {
    margin-left: 16px;
    .name {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  &-icon-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .icon {
      width: 36px;
    }
  }
}
</style>
