
import { defineComponent } from "vue";

import labels from "@/mixins/labels.json";

export default defineComponent({
  name: "Logo",
  data() {
    return {
      labels: labels
    };
  },
  methods: {
    execHome() {
      this.$router.push("/");
    }
  }
});
