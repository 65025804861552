
import { defineComponent } from "vue";

export default defineComponent({
  name: "NaviItem",
  props: {
    data: Object
  },
  methods: {
    exec(): void {
      if ((this as any).data.routerName) {
        this.$router.push((this as any).data.routerName);
      } else {
        window.open((this as any).data.link, "_blank");
      }
    }
  }
});
