
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";

import Link from "@/components/atoms/Link.vue";

export default defineComponent({
  name: "Actor",
  components: {
    Link
  },
  data() {
    return {
      actor: {}
    };
  },
  created() {
    db.collection("actors")
      .where("id", "==", Number(this.$route.params.actorId))
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          this.actor = doc.data();
        });
      });
  },
  methods: {
    execLink(link: string) {
      window.open(link, "_blank");
    }
  }
});
