
import { defineComponent } from "vue";

import GlobalHeader from "@/components/layouts/GlobalHeader.vue";
import GlobalFooter from "@/components/layouts/GlobalFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    GlobalHeader,
    GlobalFooter
  }
});
