<template>
  <div class="navi">
    <navi-item v-for="(item, index) in list" :key="index" :data="item" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { NAVI_LIST } from "@/mixins/naviList.ts";
import NaviItem from "@/components/atoms/NaviItem.vue";

export default defineComponent({
  name: "Navi",
  components: {
    NaviItem
  },
  data() {
    return {
      list: NAVI_LIST
    };
  }
});
</script>

<style lang="scss" scoped>
.navi {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .navi-item {
    margin: 0px 8px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

@media screen and (min-width: 720px) {
  .navi-item {
    margin: 0px 16px !important;
  }
}
</style>
