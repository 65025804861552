<template>
  <div class="article">
    <div
      class="article-wrapper"
      v-if="
        Number(article.release_date?.seconds) <
        Number(String(Date.now()).substr(0, 10))
      "
    >
      <div class="clip-wrapper">
        <div class="clip">
          <iframe
            :src="`https://www.youtube.com/embed/${article.youtube_id}`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="article-header">
        <div class="title">{{ article.title }}{{ article.type === 1 ? "&nbsp;#" + article.id : "" }}</div>
        <div class="date">{{ formatDate }}</div>
      </div>
      <div class="article-content">
        <div
          class="text"
          v-for="(text, index) in formatDescription"
          :key="index"
        >
          {{ text }}
        </div>
      </div>
      <div class="article-footer">
        <div class="link-component">
          <div v-if="article.links" class="link-title">
            {{ labels.linkTitle }}
          </div>
          <Link
            v-for="(item, index) in article.links"
            :key="index"
            :label="item.label"
            :url="item.url"
            :sns="false"
          />
        </div>
        <div class="actor-component">
          <div class="actor-component-wrapper">
            <actor-icon
              v-for="(actor, index) in article.actorList"
              :key="index"
              :data="actor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";
import { ACTOR_LIST } from "@/mixins/actorList";
import moment from "moment";
import labels from "@/mixins/labels.json";

import ActorIcon from "@/components/atoms/ActorIcon.vue";
import Link from "@/components/atoms/Link.vue";

export default defineComponent({
  name: "Article",
  components: {
    ActorIcon,
    Link,
  },
  data() {
    return {
      article: {},
      labels: labels,
    };
  },
  created() {
    db.collection("articles")
      .where("type", "==", Number(this.$route.params.typeId))
      .where("id", "==", Number(this.$route.params.articleId))
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach(async (doc: any) => {
          this.article = await doc.data();
          (this as any).article.actorList = this.setActorData();
        });
      });
  },
  computed: {
    formatDate() {
      return moment
        .unix((this as any).article.release_date?.seconds)
        .format("YYYY年MM月DD日");
    },
    formatDescription() {
      return (this as any).article.description?.split("|n");
    },
  },
  methods: {
    setActorData() {
      const actorList: any = [];
      const actors = (this as any).article.actors;
      actors.forEach((id: number) => {
        const actorData = ACTOR_LIST.filter((actor: any) => {
          return actor.id === id && actor.visible;
        });
        actorList.push(actorData[0]);
      });
      return actorList;
    },
  },
});
</script>

<style lang="scss" scoped>
.article {
  padding-top: calc(16px + constant(safe-area-inset-top));
  padding-right: calc(16px + constant(safe-area-inset-right));
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-left: calc(16px + constant(safe-area-inset-left));
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  padding-left: calc(16px + env(safe-area-inset-left));
  &-wrapper {
    padding: 16px;
    width: 100%;
    box-shadow: 0 0 4px black;
    .clip-wrapper {
      width: 100%;
      max-width: 720px;
      .clip {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        margin-bottom: 8px;
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .article-header {
      margin: 8px 0px;
      .title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .date {
        font-size: 16px;
        color: gray;
        margin-bottom: 8px;
      }
    }
    .article-content {
      margin-bottom: 16px;
      .text {
        min-height: 32px;
        line-height: 32px;
      }
    }
    .article-footer {
      display: flex;
      justify-content: space-between;
      .link-component {
        .link-title {
          font-size: 20px;
          margin-bottom: 8px;
        }
      }
      .actor-component {
        display: flex;
        justify-content: flex-end;
        &-wrapper {
          padding-top: 4px;
          .actor-icon {
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .article-footer {
    display: block !important;
    .link-component {
      margin-bottom: 24px;
    }
  }
}
</style>
