<template>
  <div class="top-description">
    <div>{{ labels.topDescription1 }}</div>
    <div>{{ labels.topDescription2 }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import labels from "@/mixins/labels.json";

export default defineComponent({
  name: "TopDescription",
  data() {
    return {
      labels: labels
    };
  }
});
</script>

<style lang="scss" scoped>
.top-description {
  font-size: 16px;
  div {
    line-height: 24px;
  }
}

@media screen and (max-width: 320px) {
  .top-description {
    font-size: 12px;
  }
}
</style>
