
import { defineComponent } from "vue";

import Navi from "@/components/molecules/Navi.vue";

export default defineComponent({
  name: "GlobalFooter",
  components: {
    Navi
  }
});
