
import { defineComponent } from "vue";

export default defineComponent({
  name: "Link",
  props: {
    label: String,
    url: String,
    sns: Boolean
  },
  computed: {
    setSnsClass() {
      return (this as any).sns ? "" : "blue";
    }
  },
  methods: {
    execLink() {
      window.open(this.url, "_blank");
    }
  }
});
