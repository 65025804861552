
import { defineComponent } from "vue";
import { db, storage } from "@/plugins/firebase";
import { ACTOR_LIST } from "@/mixins/actorList";
import moment from "moment";
import labels from "@/mixins/labels.json";

import ActorIcon from "@/components/atoms/ActorIcon.vue";
import Link from "@/components/atoms/Link.vue";

export default defineComponent({
  name: "Article",
  components: {
    ActorIcon,
    Link,
  },
  data() {
    return {
      article: {},
      labels: labels,
    };
  },
  created() {
    db.collection("articles")
      .where("type", "==", Number(this.$route.params.typeId))
      .where("id", "==", Number(this.$route.params.articleId))
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach(async (doc: any) => {
          this.article = await doc.data();
          (this as any).article.actorList = this.setActorData();
        });
      });
  },
  computed: {
    formatDate() {
      return moment
        .unix((this as any).article.release_date?.seconds)
        .format("YYYY年MM月DD日");
    },
    formatDescription() {
      return (this as any).article.description?.split("|n");
    },
  },
  methods: {
    setActorData() {
      const actorList: any = [];
      const actors = (this as any).article.actors;
      actors.forEach((id: number) => {
        const actorData = ACTOR_LIST.filter((actor: any) => {
          return actor.id === id && actor.visible;
        });
        actorList.push(actorData[0]);
      });
      return actorList;
    },
  },
});
