<template>
  <div class="link" :class="setSnsClass" @click.stop="execLink">
    <div class="dot" v-if="!sns">・</div>
    <div class="text">{{ label }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Link",
  props: {
    label: String,
    url: String,
    sns: Boolean
  },
  computed: {
    setSnsClass() {
      return (this as any).sns ? "" : "blue";
    }
  },
  methods: {
    execLink() {
      window.open(this.url, "_blank");
    }
  }
});
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 16px;
  display: flex;
  .dot {
  }
  .text {
    text-decoration: underline;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

.blue {
  .text {
    color: #000080;
  }
}
</style>
